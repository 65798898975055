'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider",
        function($stateProvider) {
            $stateProvider
                .state('index.account-detail', {
                    url: '/account-detail/:operation',
                    templateUrl: 'app/account/details/account.html',
                    controller: 'AccountCtrl'
                });
        }
    ]);